import FirstBanner from "../components/FirstBanner";
import splash_img from "../assets/splash-blue.svg";

import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ButtonBubble from "../components/ui/ButtonBubble";
import {useLanguage} from '../lang/LangProvider';

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}
const PageNotFound = () => {
      const { translate } = useLanguage();
    
    document.title = "404 Error";

    return (
        <>
            <motion.div className="position-relative bg-color-lightblue"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <FirstBanner color1="blue" color2="lightblue">
                    <div className="mb-2 button-title mx-auto" >404
                <h1 className="secondary-title text-center px-big-1">{translate('404')}</h1></div>

                    <Link to="/">
                            <div className='text-center mt-3'>
                                <ButtonBubble className="">

                                    <div className="button-text text-center">{translate('backHome')}</div>
                                </ButtonBubble>
                            </div>
                        </Link>
                </FirstBanner>
               
                <img className="splash-bottom" src={splash_img} alt="splash"/>
            </motion.div>
        </>
    );
}

export default PageNotFound;