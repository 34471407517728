import splash_img from "../assets/splash.webp";
import FirstBanner from "../components/FirstBanner";
import ArticleList from "../components/Article";
import ticketDummy from '../assets/ticket/ticket-1.png';
import ticketLogo from '../assets/ticket/ic-ticket.svg';
import useFetch from "../customFunction/useFetch";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ButtonBubble from "../components/ui/ButtonBubble";
import { useLanguage } from '../lang/LangProvider';

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}



const Article = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { translate,currentLanguage } = useLanguage();

    const { data: dataNews, isPending: isPendingNews, error: errorNews } = useFetch(apiUrl + 'news?lang='+currentLanguage);
    localStorage.setItem("titlePage", translate('article'));
   
    return (
        <motion.div className="position-relative"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <FirstBanner color1="blue" color2="lightblue">
                <div className="button-title bg-yellow" >{translate('menus.article')}</div>
            </FirstBanner>
            <div className="py-big-3">
                <div className="container">
                    {isPendingNews && ' '}
                    {errorNews && errorNews.message}
                    {dataNews && <ArticleList data={dataNews} />}
                </div>
            </div>
            <img className="splash-bottom" src={splash_img} alt="splash"/>
        </motion.div>
    );
}

export default Article;