
import useFetch from "../customFunction/useFetch";
import PageDetail from "../components/PageDetail";
import { useLocation } from 'react-router-dom';
import { motion } from "framer-motion";
import { useLanguage } from '../lang/LangProvider';
import Seo from "../components/Seo"; // Import Seo component
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ArticleDetailMeta from "../components/Seo/ArticleDetailMeta";


const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}

const ArticleDetail = () => {
    const location = useLocation().pathname.split("/").pop();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { currentLanguage } = useLanguage();
    const { data, isPending, error } = useFetch(apiUrl + 'news?lang='+currentLanguage+'&seo=' + location);
    const [metadata, setMetadata] = useState(() => JSON.parse(localStorage.getItem('metadata')) || {});
    
    useEffect(() => {
        if (data) {
            const title = data.meta_title || data.name
            localStorage.setItem("titlePage", title);
            const storedMetadata = JSON.parse(localStorage.getItem('metadata')) || {};
            setMetadata(storedMetadata);
        }
      }, [data]);
    
    return (
        <>

        {data && (
            <>
                <Seo
                    data={{
                        ...metadata,
                        description: data.meta_description,
                        keyword: data.meta_keyword,
                        web_title: data.meta_title
                    }}
                />
                <ArticleDetailMeta data={data} />
            </>
            )}
            {isPending && ' '}
            {error && error.message}
            {
                data && <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <PageDetail data={data} />
                </motion.div>
            }
        </>
    );
}

export default ArticleDetail;