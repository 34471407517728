import FirstBanner from "../../components/FirstBanner";
import AccordionList from "../../components/Accordion";

import bus from '../../assets/getting-here/bus.svg';
import logo from "../../assets/wahoo-logo.png";
import './style.scss';
import { useLoadScript } from "@react-google-maps/api";
import { motion } from "framer-motion";
import parse from 'html-react-parser';
import useFetch from "../../customFunction/useFetch";
import {useLanguage } from '../../lang/LangProvider';

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}
const TesHTML = ({data}) => {
    return (
        <motion.div variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit">
            <div className="tips-div mt-big-2">
                <div className="tips-list">
                    <div className="icon-div">
                        <img src={bus} alt="bus1" />
                    </div>
                    <div className="tips-content">

                        {/* <h5 className="third-title text-blue">DAMRI Bus from Alun-alun Bandung via Pasteur Toll Road</h5> */}
                        <h5 className="third-title text-blue">{data.publicTransport1} </h5>
                    </div>
                </div>
                <div className="tips-list">
                    <div className="icon-div">
                        <img src={bus}  alt="bus2"/>
                    </div>
                    <div className="tips-content">
                        {/* <h5 className="third-title text-blue">Trans Bus from Alun-alun Bandung via Cimahi-Cimamere</h5> */}
                        <h5 className="third-title text-blue">{data.publicTransport2}</h5>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}
const GettingHere = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { translate,currentLanguage } = useLanguage();
    const { data } = useFetch(apiUrl + 'contact');
    const { data : gettinghere } = useFetch(apiUrl + 'page?section=8&lang='+currentLanguage);
    const content = gettinghere && gettinghere.content ? JSON.parse(gettinghere.content):{}
    const openHour = content.open ??''

    const transport = {
        publicTransport1: content.public_transport_1 ??'',
        publicTransport2: content.public_transport_2 ??''
    }
    const dataList = [{
        "userId": 1,
        "id": 1,
        "name": "Public Transport",
        "body": "",
        "html": <TesHTML data={transport}  />,
    }];
    const { isLoaded } = useLoadScript({
        // googleMapApiKey: "AIzaSyBVyEbFUprRqNIw6tkC5Y1dDmKUTvV7DaE" // Add your API key
        googleMapApiKey: "AIzaSyDYv4i7S2N1gylpOQgwq47l_0gFbKf2msc" // Add your API key
    });
    localStorage.setItem("titlePage", translate('menus.getting_here'));

    return (
        <>
            <div className="position-relative">
                <FirstBanner color1="yellow">
                    <div className="mx-auto button-title">{translate('menus.getting_here')}</div>
                    {/* <h1 className="text-center mt-big-1 main-title text-blue">How to get to Wahoo Waterworld!</h1> */}
                    <h1 className="text-center mt-big-1 main-title text-blue">{translate('getting_here.sub_heading')}</h1>
                </FirstBanner>
                <div className="dining-div for-map mt-big-2 pb-6 mb-6 px-4 map-getting-here">
                    <div className="dining-img mb-6">
                        <div className="imageclip mapp">
                            <div id="div-for-map">
                                <iframe
                                    title="video-footer"
                                    src={gettinghere && gettinghere.map ? parse(gettinghere.map) : ''}
                                    width="100%"
                                    height="100%"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                ></iframe>
                                {/* {isLoaded ? <Map /> : null} */}
                            </div>
                            <svg width="0" height="0">
                                <clipPath id="svgClip" clipPathUnits="objectBoundingBox"><path d="m 0.892 0.092 C 1.016 0.226 1.021 0.792 0.877 0.888 C 0.683 1.027 0.273 0.991 0.139 0.891 S -0.049 0.247 0.128 0.094 c 0.156 -0.108 0.541 -0.111 0.739 -0.017 Z"></path></clipPath>
                            </svg>
                        </div>
                    </div>
                    <div className="dining-list bg-color-purple text-white map-getting-info">
                        <div className="flex-dekstop">
                            <img className="logo_" src={logo} alt="logo" />
                            {
                                data && data.address ?
                                    parse(data.address) :
                                    <p className="">Jl. Pancatengah Nomor 1<br></br>Kota Baru Parahyangan<br></br>Bandung. 40553</p>
                            }
                        </div>
                        <div className="address">
                            <div className="contact">
                            {
                                    data && data.phone ?
                                        <a href={data.phone_link} target="_blank" rel="noopener noreferrer" className="single-contact">
                                            <div className="icon">
                                                <i className="fa-solid fa-phone"></i>
                                            </div>
                                            <div className="text">
                                                {data.phone}
                                            </div>
                                        </a>
                                        : ''
                                }
                                {
                                  
                                    data && data.fax ?
                                    (
                                        <div className="single-contact">
                                          {/* Icon displayed once */}
                                          <div className="icon">
                                            <i className="fa-solid fa-fax"></i>
                                          </div>
                                          {/* Fax numbers loop */}
                                          <div style={{ display: "flex", flexDirection: "column" }}>
                                          {data.fax.split(",").map((number, index) => (
                                            <a
                                              key={index}
                                              href={`tel:${number.trim().replace(/\s+/g, '')}`}  
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="contact-link"
                                              onMouseEnter={(e) => (e.currentTarget.style.color = "yellow")}
                                              onMouseLeave={(e) => (e.currentTarget.style.color = "white")}
                                            >
                                              <div className="">{number.trim()}</div>
                                            </a>
                                          ))}
                                          </div>
                                        </div>
                                      ) 
                                        : ''
                                }
                            </div>
                            <div className="contact">
                            {
                                    data && data.email ?
                                        <a href={'mailto:' + data.email} target="_blank" rel="noopener noreferrer" className="single-contact">
                                            <div className="icon">
                                                <i className="fa-solid fa-envelope"></i>
                                            </div>
                                            <div className="text">
                                                {data.email}
                                            </div>
                                        </a> : ''
                                }
                                {
                                    data && data.wa ?
                                        <a href={data.wa_link} target="_blank" rel="noopener noreferrer" className="single-contact">
                                            <div className="icon">
                                                <i className="fa-brands fa-whatsapp"></i>
                                            </div>
                                            <div className="text">
                                            {data.wa}
                                            </div>
                                        </a> : ''
                                }
                            </div>
                        </div>
                        <div>
                            <p className="text-blue text-bold">{translate('getting_here.jam_buka')}</p>
                            <h5 className="third-title">{openHour}</h5>
                        </div>
                    </div>
                </div>
                <div className="container">
                    {dataList && <AccordionList data={dataList} />}
                </div>
            </div>
        </>
    );
}
export default GettingHere;