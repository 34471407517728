import './style.scss';
import { Link, useLocation } from 'react-router-dom';
import wahooLogoWhite from '../../../assets/wahoo-logo-white.png';
import Hello from './Hello';
import cashlessGrid from '../../../assets/cashless.png';
import bottomSplash from "./footer-splash.svg";
import iconTT from '../../../assets/icon-tiktok.png';
import iconIG from '../../../assets/icon-ig.png';
import iconFB from '../../../assets/icon-fb.png';
import iconYT from '../../../assets/icon-youtube.png';
import useFetch from '../../../customFunction/useFetch';
import parse from 'html-react-parser';
import {useLanguage } from '../../../lang/LangProvider';
import React from 'react';

export default function Footer() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { translate, currentLanguage } = useLanguage();
    const { data : gettinghere } = useFetch(apiUrl + 'page?section=8&lang='+currentLanguage);
    const content = gettinghere && gettinghere.content ? JSON.parse(gettinghere.content):{}
    const openHour = content.open ??''

    const location = useLocation().pathname;
    const { data} = useFetch(apiUrl + 'contact');
    return <footer id='footer'>
        <div className="top-wave">
            <div className="footer-wave wave1"></div>
            <div className="footer-wave wave2"></div>
        </div>
        {/* <div className="top-wave">
        </div> */}
        <div className='footer-columns'>
            <div className="container alt">
                <div className="col-row">
                    <div className="col">
                        {/* <Link to="/dining" className={`main-menu ${location === '/dining' || location === '/dining' ? 'active' : ''}`}>Things To Do</Link> */}
                        {/* <Link to="/" className='main-menu'>Ride And Attraction</Link> */}
                        <Link to="/plan-your-event" className={`main-menu ${location === '/plan-your-event' ? 'active' : ''}`}>{translate('menus.plan_your_visit')}</Link>
                        <Link to="/ticket-and-package" className={`main-menu ${location === '/ticket-and-package' ? 'active' : ''}`}>{translate('menus.tickets_and_packages')}</Link>
                    </div>
                    <div className="col sub-menu-col">
                        <Link className={`sub-menu  ${location === '/booking' ? 'active' : ''}`} to="/booking">{translate('online_reservation')}</Link>
                        {/* <Link className='sub-menu' to="/weather-arrangement">Weather Arrangement</Link> */}
                        <Link className={`sub-menu  ${location === '/services' ? 'active' : ''}`} to="/services">{translate('guest_service_and_facilities')}</Link>
                        <Link className={`sub-menu  ${location === '/event-venue' ? 'active' : ''}`} to="/event-venue">{translate('event_venues')}</Link>
                    </div>
                    <div className="col sub-menu-col">
                        {/* <Link className='sub-menu' to="/merchandise">Merchandise</Link> */}
                        <Link className={`sub-menu  ${location === '/special-offer' ? 'active' : ''}`} to="/special-offer">{translate('special_offer')}</Link>
                        <Link className={`sub-menu  ${location === '/article' ? 'active' : ''}`} to="/article">{translate('article')}</Link>
                        <Link className={`sub-menu  ${location === '/tips-on-wahoo' ? 'active' : ''}`} to="/tips-on-wahoo#regulation">{translate('rules_and_regulation')}</Link>
                        <Link className={`sub-menu  ${location === '/contact-us' ? 'active' : ''}`} to="/contact-us">{translate('contact_us')}</Link>
                    </div>
                    <div className="col open-hours-col">
                        <div className="open-hours">
                            <Hello />
                            <h5>{translate('open_hour')}</h5>
                            <div className="hours">
                                {openHour}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-row no-pad">
                    <div className="col-2">
                        <div className="address">
                            <div className="logo">
                                <img src={wahooLogoWhite} alt="" />
                            </div>
                            <div className="text">
                                {
                                    data && data.address ?
                                        parse(data.address) :
                                        parse('Jl. Pancatengah Nomor 1. <br />Kota Baru Parahyangan, Bandung 40553')
                                }
                            </div>
                        </div>
                        <div className="address">
                            <div className="contact">
                                {
                                    data && data.phone ?
                                        <a href={data.phone_link} target="_blank" rel="noopener noreferrer" className="single-contact">
                                            <div className="icon">
                                                <i className="fa-solid fa-phone"></i>
                                            </div>
                                            <div className="text">
                                                {data.phone}
                                            </div>
                                        </a>
                                        : ''
                                }
                                                            {
  data && data.fax ? (
    <div className="single-contact">
      {/* Icon displayed once */}
      <div className="icon">
        <i className="fa-solid fa-fax"></i>
      </div>
      {/* Fax numbers loop */}
      <div style={{ display: "flex", flexDirection: "column" }}>
      {data.fax.split(",").map((number, index) => (
        <a
          key={index}
          href={`tel:${number.trim().replace(/\s+/g, '')}`}  
          target="_blank"
          rel="noopener noreferrer"
          className="contact-link"
          onMouseEnter={(e) => (e.currentTarget.style.color = "yellow")}
          onMouseLeave={(e) => (e.currentTarget.style.color = "white")}
        >
          <div className="">{number.trim()}</div>
        </a>
      ))}
      </div>
    </div>
  ) : (
    ''
  )
}
                            </div>

                            <div className="contact">
                                {
                                    data && data.email ?
                                        <a href={'mailto:' + data.email} target="_blank" rel="noopener noreferrer" className="single-contact">
                                            <div className="icon">
                                                <i className="fa-solid fa-envelope"></i>
                                            </div>
                                            <div className="text">
                                                {data.email}
                                            </div>
                                        </a> : ''
                                }
                                {
                                    data && data.wa ?
                                        <a href={data.wa_link} target="_blank" rel="noopener noreferrer" className="single-contact">
                                            <div className="icon">
                                                <i className="fa-brands fa-whatsapp"></i>
                                            </div>
                                            <div className="text">
                                            {data.wa}
                                            </div>
                                        </a> : ''
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="payments">
                            <h5 className='nowrap'>
                                <span>C</span>
                                <span>a</span>
                                <span>s</span>
                                <span>h</span>
                                <span>l</span>
                                <span>e</span>
                                <span>s</span>
                                <span>s</span>
                                <span>&nbsp;</span>
                                <span>P</span>
                                <span>a</span>
                                <span>y</span>
                                <span>m</span>
                                <span>e</span>
                                <span>n</span>
                                <span>t</span>
                                <span>s</span>
                            </h5>
                            {/* <Link to="/cashless">
                            </Link> */}
                            <img src={cashlessGrid} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-section desktop">
            <div className="container alt">
                <div className="copyright">
                  ©2023 Wahoo Waterworld. Website Design by <a href="https://www.gositus.com/" rel="noreferrer" target="_blank" >Gositus</a>
                </div>
            </div>
        </div>
        <div className="bottom-splash">
            <div className="socmed">
                {
                    data && data.tiktok ? <a href={`https://www.tiktok.com/` + data.tiktok} target="_blank" rel="noopener noreferrer"><img src={iconTT} alt="" /></a> : null
                }
                {
                    data && data.instagram ? <a href={`https://www.instagram.com/` + data.instagram} target="_blank" rel="noopener noreferrer"><img src={iconIG} alt="" /></a> : null
                }
                {
                    data && data.facebook ? <a href={`https://www.facebook.com/` + data.facebook} target="_blank" rel="noopener noreferrer"><img src={iconFB} alt="" /></a> : null
                }
                {
                    data && data.youtube ? <a href={`https://www.youtube.com/channel/` + data.youtube} target="_blank" rel="noopener noreferrer"><img src={iconYT} alt="" /></a> : null
                }
            </div>
            <div className="copyright-section">
                <div className="container alt">
                    <div className="copyright">
                        ©2023 Wahoo Waterworld. Website Design by <a href="https://www.gositus.com/" rel="noreferrer" target="_blank" >Gositus</a>
                    </div>
                </div>
            </div>
            <img src={bottomSplash} alt="" />
            <div className="mobile-spacer"></div>
        </div>
    </footer>;
}
