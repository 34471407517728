import FirstBanner from "../components/FirstBanner";
import splash_img from "../assets/splash.webp";
import SitemapList from "../components/SitemapList";
import vip from "../assets/sitemap/vip.svg";
import chill from "../assets/sitemap/chill.svg";
import river from "../assets/sitemap/river.svg";
import storage from "../assets/sitemap/storage.svg";
import atm from "../assets/sitemap/atm.svg";
import prayer from "../assets/sitemap/prayer.svg";
import gift from "../assets/sitemap/gift.svg";
import photo from "../assets/sitemap/photo.svg";
import food from "../assets/sitemap/food.svg";
import map from "../assets/sitemap/map.webp";
import mapNew from "../assets/sitemap/wahoo-map.png";
import mapWithDesc from "../assets/sitemap/map-new.jpg";
import { motion } from "framer-motion";
import {useLanguage} from '../lang/LangProvider';
import useFetch from "../customFunction/useFetch";

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}
const Sitemap = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const { translate,currentLanguage} = useLanguage();
    const { data } = useFetch(apiUrl + 'page?section=7&lang='+currentLanguage);
    const content = data && data.content ? JSON.parse(data.content):{}
    const attraction = content.attractions ??''
    const guestservices = content.guestservices??''
    const subguestservices = content.subguestservices??''

    localStorage.setItem("titlePage", translate('map.title'));


    return (
        // <motion.div className="position-relative" style={{ backgroundColor: '#c0eaff' }}
        <motion.div className="position-relative"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <FirstBanner style={{ zIndex: 1 }} color1="blue" color2="lightblue">
                <div className="mb-2 button-title bg-yellow mx-auto">{translate('map.title')}</div>
                {/* <h1 className="secondary-title text-center text-white px-big-1">Know your way around the park</h1> */}
                <h1 className="secondary-title text-center text-white px-big-1">{translate('map.sub_heading')}</h1>
            </FirstBanner>
            <div className="section position-relative pt-1 z-index pb-0">
                <div className="container">
                    {/* <div className="map-zoom">
                        <button className="zoom-in">+</button>
                        <button disabled className="zoom-out">−</button>
                    </div> */}
                    <div className="map-detail mt-big-1 mb-big-3">
                        <img className="w-100" alt="Wahoo Map" src={data && data.image_url ?baseUrl + data.image_url:''} />
                    </div>
                    <div className="">
                        <div className="layout-3 mbl-layout-2 map-legend pb-big-2 ">
                            <div className="wrapper">
                                <div style={{ backgroundColor: "#ea1d1f" }} className="button-title text-normal mb-3">{translate('attraction')}</div>
                                <div dangerouslySetInnerHTML={{ __html: attraction }} ></div>
                            </div>
                            <div className="wrapper">
                                <div className="button-title bg-yellow text-normal mb-3">{translate('guest_service')}</div>
                                <div dangerouslySetInnerHTML={{ __html: guestservices }} ></div>

                            </div>
                            <div className="wrapper">
                                <div className="button-title text-normal mb-3">{translate('guest_service')}</div>
                                <div dangerouslySetInnerHTML={{ __html: subguestservices }} ></div>

                            </div>
                            {/* <SitemapList title="Ride and Attractions" bgColor="#f6a21d" textColor="white">
                                {attractions.map((attraction, index) => (
                                    <div key={index} className="list-of-items">
                                        <div className="bullets" style={{ backgroundColor: 'red', color: 'white' }}>
                                            {index + 1}
                                        </div>
                                        <div className="listName">{attraction.name}</div>
                                    </div>
                                ))}
                            </SitemapList> */}
                            {/* <SitemapList bgColor="#f6a21d" title="Services" textColor="white">
                                {services.map((service, index) => (
                                    <div key={index} className="list-of-items">
                                        <div className="bullets" style={{ backgroundColor: '#f8ee5d' }}>
                                            {String.fromCharCode(index+65)}
                                        </div>
                                        <div className="listName">{service.name}</div>
                                    </div>
                                ))}
                            </SitemapList> */}
                        </div>
                    </div>
                </div>
                {/* <div className="spacer-footer" style={{ backgroundColor: '#c0eaff' }}></div> */}
            </div>
            {/* <img className="splash-bottom z-index" src={splash_img} /> */}
        </motion.div>
    );
}

export default Sitemap;