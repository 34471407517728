const id = {
        "welcome": "Selamat Datang",
        "login": "Masuk",
        "home" : {
            "seluncuran_air":`<span class="white anim">
                                Seluncuran air
                              </span>
                              <span class="orange anim">
                                tercepat dan pertama
                              </span>
                              <span class="blue anim">di Asia Tenggara</span>`,
            "petualangan": `
                            <span class="white anim">Petualangan air seru</span>
                            <span class="blue anim"> untuk sekeluarga</span>
            `,
            "temukan_petualangan":"Temukan petualangan seru dengan wahana dan atraksi yang mengagumkan",
            "min_tinggi":"Min. Tinggi",
            "no_tinggi":"Tidak ada minimal tinggi badan",
            "boomerango_desc":"Boomerango membawamu meluncur melalui terowongan cincin cahaya yang dramatis, dan langsung meluncur ke atas papan seluncur besar dengan peluncuran yang nyaris vertikal. Nikmati keseruan meluncur yang menegangkan dengan teriakan gembira.",
            "beli_langsung":"Beli langsung dan dapatkan harga terbaik!",
            "tiket":'Tiket',
            "sold_out":'Habis'

        },
        "plan_your_event":{
            "tarif_khusus":"Dapatkan tarif dan paket khusus kami untuk grup lebih dari 50 orang, Klik tombol di Kanan",

        },
        '404':'Halaman Tidak Ditemukan',
        'backHome':'Kembali ke Beranda',
        "getting_here":{
          "sub_heading":"Petunjuk arah ke Wahoo Waterworld",
          "jam_buka": 'Jam Buka'
        },
        "group_booking":{
          "sub_heading":"Wahoo Waterworld adalah tempat yang sempurna untuk acara khusus, ulang tahun, tamasya keluarga, atau sekelompok teman yang ingin bersenang-senang! Tim kami dapat membantu Anda dengan pertanyaan Anda dan memberi tahu Anda tarif dan paket khusus kami untuk grup lebih dari 50 orang.",
          "nama":"Nama",
          "phone":"No. Handphone",
          "event":"Pilih Event",
          "submit":"Kirim"
        },
        "accomodation":{
            "sub_heading":"Pengunjung dari luar Bandung bisa memilih Mason Pine Hotel Bandung untuk akomodasi yang berkelas dan nyaman, hotel yang dikelilingi oleh pemandangan alam yang cantik."
        },
        "dining":{
          "sub_heading":"Variasi tempat makan yang menyajikan makanan lezat & minuman segar!"
        },
        "tips":{
          "sub_heading":"Rekreasi paling menyenangkan adalah rekreasi yang terencana dengan baik! Intip tip-tip berguna ini supaya pengalamanmu di Wahoo Waterworld lancar dan aman."
        },
        "event_venue":{
          "sub_heading":"Nikmati Petualangan Seru di Setiap Perayaan & Kegiatan!"
        },
        "map":{
          'title':'Peta Lokasi',
          'sub_heading':"Jelajahi Wahoo Waterworld dengan mudah!"
      },
      "service":{
        "heading_service":"Layanan",
        "heading_facilities":"Fasilitas",
        "fasilitas_sub_heading":"Berbagai macam fasilitas yang nyaman & bersih!"
      },
      "menus":{
        "things_to_do": "Aktivitas",
        "dining": "Tempat Makan",
        "services_facilities":"Layanan dan Fasilitas",
        "event_venue":"Tempat Acara",
        "plan_your_visit":"Rencanakan Kunjungan",
        "getting_here": "Petunjuk Kesini",
        "plan_your_event":"Rencana Acara",
        "map":"Peta",
        "accomodation":"Akomodasi",
        "tips": "Saran",
        "tips_on_wahoo":"Saran di Wahoo",
        "faq": "Pertanyaan Umum",
        "rules": "Aturan-aturan",
        "term_and_condition":"Syarat dan Ketentuan",
        "tickets_and_packages":"Tiket dan Paket",
        "tiket_pass":"Tiket Masuk",
        "ride_and_attractions": "Wahana dan Atraksi",
        "buy_tickets":"Pesan Tiket",
        "article":"Artikel",
        "bookPage":'Pemesanan',
        "specialOffer":"Penawaran Khusus"
      },
      "other_attraction":"Atraksi Lainnya",
      "open_hour":"Jam Buka",
      "ceklis_setuju_full" :"Mohon pastikan Anda memahami dan menerima syarat dan ketentuan pembelian Tiket. Semua pembelian Tiket tidak dapat dikembalikan.",
      "ceklis_setuju_1" :"Mohon pastikan Anda memahami dan menerima",
      "ceklis_setuju_2" :"syarat dan ketentuan",
      "ceklis_setuju_3":"pembelian Tiket.",
      "ceklis_setuju_4":"Semua pembelian Tiket tidak dapat dikembalikan.",
      "setuju_sebelum_lanjut":"Setujui syarat & ketentuan sebelum melanjutkan",
      "tiket_note": "Harga dapat berubah sewaktu - waktu tanpa pemberitahuan terlebih dahulu",
      "tickets":"Tiket",
      "learn_more":"Pelajari Lebih Lanjut",
      "booking":'Pemesanan Grup',
      "guest_service":"Layanan Tamu",
      "attraction":"Atraksi-atraksi",
      "room_package":"Paket Kamar",
      "book_now":"Pesan Sekarang!",
      "see_more": "Lihat Lebih Lanjut",
      "online_reservation":"Pemesanan Online",
      "guest_service_and_facilities":"Layanan & Fasilitas",
      "event_venues" :"Acara & Tempat",
      "special_offer":"Penawaran Khusus",
      "rules_and_regulation":"Aturan & Regulasi",
      "article":"Artikel",
      "contact_us":"Hubungi Kami",
      "drench":"Drench",
      "thrill":"Thrill",
      "speed":"Speed",
      "rides_type":"Keseruan Wahana",
      "batasan_atraksi":"Batasan Atraksi",
      "caution":"Peringatan",
      "cahsless_payment":`      <span>P</span>
      <span>e</span>
      <span>m</span>
      <span>b</span>
      <span>a</span>
      <span>y</span>
      <span>a</span>
      <span>r</span>
      <span>a</span>
      <span>n</span>

      <span>&nbsp;</span>
      <span>N</span>
      <span>o</span>
      <span>-</span>
      <span>n</span>
      <span>t</span>
      <span>u</span>
      <span>n</span>
      <span>a</span>
      <span>i</span>`


      }
export default id