import ImageClip from "../../components/ui/ImageClip";
import pineHotel from "../../assets/plan/mason-pine.svg";
import fiveMinutes from "../../assets/plan/5minutes.png";
import "./style.scss";
import RoomPackage from "../../components/RoomPackage";
import { useState, useEffect } from "react";
import FirstBanner from "../../components/FirstBanner";
import useFetch from "../../customFunction/useFetch";
import { motion } from "framer-motion";
import {useLanguage } from '../../lang/LangProvider';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.35, duration: 0.5 },
  },
  exit: {
    y: -30,
    opacity: 0,
    transition: { duration: 0.35 },
  },
};
const Accomodation = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const apiUrl = process.env.REACT_APP_API_URL;
  const { translate,currentLanguage } = useLanguage();

  const { data, isPending, error } = useFetch(apiUrl + "accomodation?lang="+currentLanguage);
  const [accommodationPage, setAccommodationPage] = useState(null);

  useEffect(() => {
    const fetchAccommodationPage = async () => {
      try {
        const response = await fetch(apiUrl + "page?section=6&lang="+currentLanguage);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const accommodationPage = await response.json();
        setAccommodationPage(accommodationPage);
      } catch (error) {
        console.error("Error fetching term data:", error);
      }
    };

    fetchAccommodationPage();
  }, [currentLanguage]);
  localStorage.setItem("titlePage", translate('menus.accomodation'));

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="position-relative"
    >
      <FirstBanner color1="lightgreen" color2="green">
        <div className="container">
          <div className="accomodation-banner">
            <img className="py-3" src={accommodationPage && baseUrl + accommodationPage.image_heading_url} alt="Heading Logo" />
            <p className="py-3">
              {accommodationPage&& accommodationPage.heading}
              {/* Guests from outside Bandung can opt for the scenic Mason Pine Hotel Bandung for classy and convenient accommodation, surrounded by picturesque natural wonders. */}
            </p>
          </div>
        </div>
      </FirstBanner>
      <div className="section mb-big-4 mbl-mb-big-1">
        <div className="container">
          <div className="list-item mb-big-6 mbl-mb-big-1">
            <div>
              <ImageClip
                src={accommodationPage && baseUrl + accommodationPage.image_url}
              />
            </div>
            <div className="content-list">
              <img src={fiveMinutes} className="five-minutes" alt="" />
              <p className="mb-4">
                {accommodationPage && accommodationPage.caption}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section bg-color-lightgreen position-relative">
        <div className="banner-curve white on-top"></div>
        <div className="container ">
          <div className="all-day-img">
            {accommodationPage && accommodationPage.gallery_url ? (
              JSON.parse(accommodationPage.gallery_url).map(
                (imageGallery, idx) => (
                  <img
                    key={idx}
                    src={baseUrl + imageGallery}
                    alt={imageGallery ? imageGallery.split('/').pop():''}
                  />
                )
              )
            ) : (
              <p>No images available</p> // You can handle it with an error or leave it empty
            )}
          </div>
          <p className="py-big-3 px-big-3 mbl-py-big-1 mbl-px-big-1 secondary-title text-center mb-big-1" style={{ lineHeight: 'normal' }} dangerouslySetInnerHTML={{ __html: accommodationPage && accommodationPage.content }} >
          </p>
        </div>
      </div>
      <div className="section  position-relative">
        <div className="top-wave-banner">
          <div className="waveblue"></div>
          <div className="wavewhite"></div>
        </div>
        <div className="button-title bg-color-green mx-auto mb-big-2 text-medium">
          {translate('room_package')}
        </div>
        <div className="container">{data && <RoomPackage rooms={data} />}</div>
      </div>
      <div className="spacer-footer bg-color-lightgreen"></div>
    </motion.div>
  );
};

export default Accomodation;
