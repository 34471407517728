import FirstBanner from "../components/FirstBanner";
import octo from "../assets/gurita.png";
import RideCircularSliderDetail from "../components/RideCircularSliderDetail";
import Bar from "../components/RideCircularSliderDetail/Bar";
import ageIndicator from "../components/RideCircularSliderDetail/age-indicator.png";
import TrailerVideo from "../components/TrailerVideo";
import AttractionOther from "../components/AttractionOther";
import waveTop from '../components/TicketCircularSlider/wave-top.svg';
import splash from "../components/TicketCircularSlider/splash.png";
import { useLocation } from 'react-router-dom';
import useFetch from "../customFunction/useFetch";
import parse from 'html-react-parser';
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useLanguage } from '../lang/LangProvider';

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}

const RideAttractionsDetail = () => {
    const location = useLocation().pathname.split("/").pop();
    const apiUrl = process.env.REACT_APP_API_URL;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const { translate,currentLanguage } = useLanguage();
    const { data, isPending, error } = useFetch(apiUrl + 'ride_attraction?lang='+currentLanguage);
    const otherAttractions = data && data.filter(other => {
        return other.seo_url !== location;
    });
    const currentAttraction = data && data.filter(current => {
        return current.seo_url === location;
    });
    if (currentAttraction && currentAttraction[0]) {
        localStorage.setItem("titlePage", currentAttraction[0].name);
    }
    return (
        <>
            {/* {isPending && ' '} */}
            {/* {error && error.message} */}
            {currentAttraction && currentAttraction.length > 0 ?
                <>
                    <motion.div className="position-relative"
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        <FirstBanner color1="blue" color2="lightblue">
                            <div className="container text-center">
                                <h1 className="extra-big-title text-white mb-1 font-sallomae">{currentAttraction[0].name}</h1>
                                {/* <p className="text-white text-bold third-title px-big-1 mb-big-2">{currentAttraction[0].description_detail}</p> */}
                            </div>
                        </FirstBanner>
                        <div className="section pt-2 overflow-hidden">
                            {JSON.parse(currentAttraction[0].image_gallery).length !== 0 && <RideCircularSliderDetail attractions={JSON.parse(currentAttraction[0].image_gallery)} />}
                            <div className="container">
                                <div className="layout-2">
                                    <div className="ride-data-detail">
                                        <div className="d-flex w-75 w-75 w-mbl-100 mb-big-1">
                                            <div className="icon">
                                                {
                                                    currentAttraction[0].mascot && JSON.parse(currentAttraction[0].mascot).name !== null && JSON.parse(currentAttraction[0].mascot).name !== "" && JSON.parse(currentAttraction[0].mascot).name !== 'null' &&
                                                    <img width={100} className="me-3" src={baseUrl + JSON.parse(currentAttraction[0].mascot).url} alt={`${currentAttraction[0].name} Mascot`} />
                                                }
                                            </div>
                                            <div className="text">
                                                {
                                                    currentAttraction[0].age > 0 ?
                                                        <h5 className="text-blue secondary-title">{translate('home.min_tinggi')} {currentAttraction[0].age} cm</h5> : <h5 className="text-blue secondary-title">{translate('home.no_tinggi')}</h5>
                                                }
                                                {/* <p>And adult must supervise<br></br> children under {currentAttraction[0].age}.</p> */}

                                            </div>
                                        </div>
                                        <div className="circular-slider-section">
                                            <div className="description">
                                                <div className="ride-data detail w-75 w-mbl-100">
                                                    <div className="stats">
                                                        <div className="single-stat">
                                                            <div className="third-title text-blue name text-medium">
                                                            {translate('drench')}
                                                            </div>
                                                            <Bar level={currentAttraction[0].drench} color="#52c9e7" />
                                                        </div>
                                                        <div className="single-stat">
                                                            <div className="third-title text-blue name text-medium">
                                                            {translate('thrill')}
                                                            </div>
                                                            <Bar level={currentAttraction[0].thrill} color="#f6511d" />
                                                        </div>
                                                        <div className="single-stat">
                                                            <div className="third-title text-blue name text-medium">
                                                            {translate('speed')}
                                                            </div>
                                                            <Bar level={currentAttraction[0].speed} color="#dc3735" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ride-data detail pe-big-1">
                                                    <div className="type text-medium mb-3">
                                                    {translate('rides_type')}: {currentAttraction[0].category_name}
                                                    </div>
                                                    <div >
                                                        {/* <p>{currentAttraction[0].description}</p> */}
                                                        {parse(currentAttraction[0].description_detail)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="mb-big-1">
                                            <h5 className="third-title text-blue">{translate('batasan_atraksi')}</h5>
                                            <div>{parse(currentAttraction[0].attraction)}</div>
                                        </div>
                                        <div>
                                            {
                                                currentAttraction[0].caution &&
                                                <>
                                                    <h5 className="third-title text-blue">{translate('caution')}</h5>
                                                    <div>{parse(currentAttraction[0].caution)}</div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section pt-0 d-none">
                            <div className="container octo-ride-attract">
                                {/* <img src={octo} className="float-anim" alt="" /> */}
                                {
                                    currentAttraction[0].mascot && JSON.parse(currentAttraction[0].mascot).name !== null && JSON.parse(currentAttraction[0].mascot).name !== "" && JSON.parse(currentAttraction[0].mascot).name !== 'null' &&
                                    <img className="float-anim" src={baseUrl + JSON.parse(currentAttraction[0].mascot).url} alt={`${currentAttraction[0].name} Mascot`} />
                                }
                                <div className="div-chat">
                                    Apakah Anda cukup berani untuk melewati tikungan dan belokan yang menakutkan untuk menemukan harta karun? Pergilah ke Abyss dan Caterpillar dan beri hormat kepada Kapten Oz. Dia mungkin pemarah, tetapi sebagai pemburu harta karun yang berpengalaman, dia akan membantu Anda menemukan emas jika Anda berada di sisi baiknya. Sebagai gurita berwarna merah muda dan ungu dengan topi bajak laut, dia mudah dikenali, tapi hati-hati, Anda mungkin kehilangan dia di antara Abyss dengan warna yang sama.
                                </div>
                            </div>
                        </div>
                        {/* <div className="section">
                            <div className="container">
                                <div className="position-relative">
                                    <div className="button-title bg-yellow top-center z-index">Video</div>
                                    <div className="border-radius overflow-hidden position-relative">
                                        <TrailerVideo url={`https://www.youtube.com/embed/${currentAttraction[0].video_url}`} placeholder={baseUrl + JSON.parse(currentAttraction[0].image_url).main_image} />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="section pb-0 pt-0 yellow section-other-attraction">
                            <div className="ticket-circular-slider-section">
                                <div className="wave wave-top mb-6">
                                    <img className="img-wave" src={waveTop} alt="" />
                                    <div className="ornaments">
                                        <div className="right splash">
                                            <img src={splash} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="div-content-other">
                                <div className="container">
                                    <h5 className="secondary-title text-orange text-italic mb-big-1">{translate('Atraksi Lainnya')}</h5>
                                    {otherAttractions && <AttractionOther start={currentAttraction[0].sort} data={otherAttractions} />}
                                </div>
                            </div>
                        </div>
                        <div className="spacer-footer bg-color-yellow"></div>
                    </motion.div>
                </> : null
            }

        </>

    );
}

export default RideAttractionsDetail;