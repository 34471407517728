import './style.scss';
import ticketDummy from '../../assets/ticket/ticket-1.png';
import ticketLogo from '../../assets/ticket/ic-ticket.svg';
import ButtonBubble from '../ui/ButtonBubble';
import { Link } from 'react-router-dom';
const stripTagsAndLimitChars = (html, maxChars) => {
    // Remove HTML tags using a regular expression
    const strippedText = html.replace(/<[^>]+>/g, '');
  
    // Limit the number of characters
    const limitedText = strippedText.slice(0, maxChars);
  
    return limitedText+'...';
  };
const ArticleList = ({ data }) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    return (
        <>
            {data.map((dataticket, index) => (
                <div key={index} className="list-item pb-6 mb-6 px-4">
                    <div>
                    <div className='imageclip'>
                        <Link to={dataticket.seo_url}>
                        <img src={baseUrl + dataticket.image_url} alt={dataticket.name} />
                            <svg width="0" height="0"><clipPath id="svgClip" clipPathUnits="objectBoundingBox"><path d="m 0.892 0.092 C 1.016 0.226 1.021 0.792 0.877 0.888 C 0.683 1.027 0.273 0.991 0.139 0.891 S -0.049 0.247 0.128 0.094 c 0.156 -0.108 0.541 -0.111 0.739 -0.017 Z"></path></clipPath></svg>
                        </Link>
                            
                    </div>
                    </div>
                    <div className="content-list event-velue-list">
                            <div className="big-heading text-left">
                                <div className="button-title text-normal bg-yellow mb-3">
                                <Link to={dataticket.seo_url}>
                                    {dataticket.name}
                                    </Link>
                                    </div>
                                </div>
                                <div className="info-venue"><div className="venue-list"><i className="ico fa-solid fa-calendar"></i>{dataticket.start}</div></div>
                        <p>{stripTagsAndLimitChars(dataticket.content,270)}</p>
                    </div>
                </div>
            ))}
        </>
    );
}

export default ArticleList;