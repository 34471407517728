import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useLanguage } from "../../lang/LangProvider";

const ArticleDetailMeta = ({ data }) => {
    const formattedStartTime = formatToISO(data.start);
    const formattedEditedTime = formatToISO(data.date_edited);
    const location = useLocation().pathname.split("/").pop();
   
    const apiUrl = process.env.REACT_APP_BASE_URL;
    const { currentLanguage } = useLanguage();


    return (
        <Helmet>
            <meta
                property="article:published_time"
                content={formattedStartTime}
            />
            <meta
                property="article:modified_time"
                content={formattedEditedTime}
            />
            <meta
                property="og:updated_time"
                content={formattedEditedTime}
            />
            <link rel="alternate" type="application/json+oembed" href={apiUrl + 'article/xml/' +location+ '?lang='+currentLanguage}/>
	        <link rel="alternate" type="text/xml+oembed" href={apiUrl + 'article/json/' +location+ '?lang='+currentLanguage} />
            <meta property="og:type" content="Article" />

        </Helmet>
    );
};


const formatToISO = (dateString) => {
    const date = new Date(dateString);
    return `${date.toISOString().split('.')[0]}+00:00`;
};

export default ArticleDetailMeta;