import "./App.scss";
import {Routes, Route, useLocation, Outlet} from "react-router-dom";
import Home from "./page/Home";
import WhatsOn from "./page/ThingsToDo/WhatsOn";
import Header from "./components/ui/Header";
import Footer from "./components/ui/Footer";
import Dining from "./page/ThingsToDo/Dining";
import EventVenue from "./page/ThingsToDo/EventVenue";
import Services from "./page/ThingsToDo/Services";
import WhatsOnDetail from "./page/ThingsToDo/WhatsOnDetail";
import TransitionPage from "./components/ui/TransitionPage";
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import {gsap} from "gsap";
import {useEffect, useState, useRef} from "react";
import PlanYourEvent from "./page/PlanYourVisit/PlanYourEvent";
import PlanEventDetail from "./page/PlanYourVisit/PlanEventDetail";
import Accomodation from "./page/PlanYourVisit/Accomodation";
import GroupBooking from "./page/PlanYourVisit/GroupBooking";
import TipsOnWahoo from "./page/PlanYourVisit/TipsOnWahoo";
import GettingHere from "./page/PlanYourVisit/GettingHere";
import TicketPass from "./page/TicketPass";
import Merchandise from "./page/Merchandise";
import Cashless from "./page/Cashless";
import PageNotFound from "./page/PageNotFound";
import SpecialOffer from "./page/SpecialOffer";
import SpecialOfferDetail from "./page/SpecialOfferDetail";
import CashlessDetail from "./page/CashlessDetail";
import ContactUs from "./page/ContactUs";
import WeatherArrangement from "./page/WeatherArrangement";
import RideAttractionsDetail from "./page/RideAttractionsDetail";
import Article from "./page/Article";
import ArticleDetail from "./page/ArticleDetail";
import Sitemap from "./page/Map";
import PageLoading from "./components/PageLoading";
import {AnimatePresence} from 'framer-motion';
import Booking from "./page/Booking";
import TermAndCondition from "./page/TermAndCondition";
import Seo from "./components/Seo";
import { useLanguage } from './lang/LangProvider';  

function App() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const { currentLanguage } = useLanguage();

    const [waiting, setWaiting] = useState(true);
    const [metadata, setMetadata] = useState(null);

    // document.title = "Wahoo Waterworld - Asia's First All-weather Water Park";
    useEffect(() => {
        // Scroll to top on route change
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 600);
    }, [location]);
    
    useEffect(() => {
        // Simulate a loading screen
        setTimeout(() => {
            setWaiting(false);
        }, 4500);
    }, []);

    useEffect(() => {
        const fetchMetadata = async () => {
            const titlePage = localStorage.getItem('titlePage') ??''

            try {
                const response = await fetch(
                    `${apiUrl}metadata?lang=${currentLanguage}&uri=${location.pathname}&titlePage=${titlePage}`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch metadata");
                }
                const data = await response.json();
                setMetadata(data);
                localStorage.setItem("metadata", JSON.stringify(data));
            } catch (error) {
                console.error("Error fetching metadata:", error);
                setMetadata(null);
                localStorage.setItem("metadata", JSON.stringify(null));

            }
        };

        fetchMetadata();
    }, [location.pathname, currentLanguage, apiUrl]);

    return (
        <div className="App">
            {metadata && <Seo data={metadata}/>}
            <div className="page-wrapper">
                    {
                        waiting && <PageLoading />
                    }
                    <Header />
                        <div className="wrapper-route">
                        <AnimatePresence>
                                <Routes location={location} key={location.key}>
                                    <Route path="/" element={<Home/>}/>
                                    <Route path="/whatson" element={<WhatsOn />} />
                                    <Route path="/whatson/:seo" element={<WhatsOnDetail />} />
                                    <Route path="/dining" element={<Dining />} />
                                    <Route path="/event-venue" element={<EventVenue />} />
                                    <Route path="/services" element={<Services />} />
                                    <Route path="/plan-your-event" element={<PlanYourEvent />} />
                                    <Route path="/accomodation" element={<Accomodation />} />
                                    <Route path="/group-booking" element={<GroupBooking />} />
                                    <Route path="/tips-on-wahoo" element={<TipsOnWahoo />} />
                                    <Route path="/getting-here" element={<GettingHere />} />
                                    <Route path="/plan-your-event/:seo" element={<PlanEventDetail />} />
                                    <Route path="/ticket-and-package" element={<TicketPass />} />
                                    <Route path="/merchandise" element={<Merchandise />} />
                                    <Route path="/cashless" element={<Cashless />} />
                                    <Route path="/cashless/:seo" element={<CashlessDetail />} />
                                    <Route path="/weather-arrangement" element={<WeatherArrangement />} />
                                    <Route path="/special-offer" element={<SpecialOffer />} />
                                    <Route path="/special-offer/:seo" element={<SpecialOfferDetail />} />
                                    <Route path="/contact-us" element={<ContactUs />} />
                                    <Route path="/map" element={<Sitemap />} />
                                    <Route path="/booking" element={<Booking />} />
                                    <Route path="/term-and-condition" element={<TermAndCondition />} />
                                    <Route path="/ride-attraction/:seo" element={<RideAttractionsDetail />} />
                                    <Route path="/article" element={<Article />} />
                                    <Route path="/article/:seo" element={<ArticleDetail />} />
                                                                {/* 404 Route */}
                                    <Route path="*" element={<PageNotFound />} />

        </Routes>
                        </AnimatePresence>
                        </div>
                    <Footer />
        
            </div>
            <TransitionPage />
        </div >
    );
}

export default App;

